import Background from "./background/Background";
import "./Home.scss";
import HomeButton from "./HomeButton";

const Home = () => {
  return (
    <>
      <Background />
      <HomeButton targetPageNumber={1} className={"home__page-button"} content={"See my work"} />
    </>
  );
};

export default Home;
