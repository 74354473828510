import { useEffect, useRef, useState } from "react";
import "./Background.scss";
import BackgroundItem from "./BackgroundItem";
import Marquee from "react-fast-marquee";

const Background = () => {
  const container = useRef<HTMLDivElement>(null);

  const [containerSize, setContainerSize] = useState<any>();
  const [elementSize, setElementSize] = useState<any>();
  const [elementNumberWidth, setElementNumberWidth] = useState<number>();
  const [elementNumberHeight, setElementNumberHeight] = useState<number>();

  const getContainerSize = () => {
    setContainerSize({ width: container.current?.clientWidth, height: container.current?.clientHeight });
  };

  const getElementSize = (eSize: Object) => {
    setElementSize(eSize);
  };

  useEffect(() => {
    getContainerSize();

    if (elementSize && containerSize) {
      let numberWidth: number = containerSize.width / elementSize.width;
      let numberHeight: number = containerSize.height / elementSize.height;
      let widthTotal: number = Math.ceil(numberWidth);
      let heightTotal: number = Math.ceil(numberHeight / 2);
      setElementNumberWidth(widthTotal);
      setElementNumberHeight(heightTotal);
    }

    if (elementNumberWidth) {
    }
  }, [elementSize]);

  const nameContent = [...Array(elementNumberWidth)].map((item, index) => {
    return <BackgroundItem getElementSize={getElementSize} text={"ollie hermans"} key={"name" + index} />;
  });
  const devContent = [...Array(elementNumberWidth)].map((item, index) => {
    return <BackgroundItem getElementSize={getElementSize} text={"web developer"} key={"name" + index} />;
  });

  const totalContent = [...Array(elementNumberHeight)].map((item, index) => {
    return (
      <div key={"total" + index}>
        <Marquee gradient={false} className={"marquee"} speed={30}>
          {nameContent}
        </Marquee>
        <Marquee gradient={false} className={"marquee"} speed={30}>
          {devContent}
        </Marquee>
      </div>
    );
  });

  return (
    <div className="background">
      <div className="background__container" ref={container}>
        {totalContent}
      </div>
    </div>
  );
};

export default Background;
