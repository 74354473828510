import { useState } from "react";
import "./Projects.scss";
import next from "./../../assets/navigate_next.svg";
import prev from "./../../assets/navigate_before.svg";
import HomeButton from "../home/HomeButton";

const Projects = () => {
  const projects = ["pomodoro", "video-streaming-app", "job-listing", "expenses-chart", "audiophile", "photosnap", "countries"];
  const [selectedProject, setSelectedProject] = useState(1);
  const project = projects[selectedProject];
  const [currentScroll, setCurrentScroll] = useState(window.scrollY);

  let animationClassLeft = "fly-in-left";
  let animationClassRight = "fly-in-right";

  const handleScroll = () => {
    setCurrentScroll(window.scrollY);
  };
  window.addEventListener("scroll", handleScroll);

  if (currentScroll >= window.innerHeight - window.innerHeight * 0.2) {
    animationClassLeft = "";
    animationClassRight = "";
    window.removeEventListener("scroll", handleScroll);
  }

  const clickHandler = (e: any) => {
    let element = e.target as HTMLElement;
    if (element.id === "next") {
      if (selectedProject === projects.length - 1) {
        setSelectedProject(0);
      } else {
        setSelectedProject(prevState => {
          return prevState + 1;
        });
      }
    } else if (element.id === "prev") {
      if (selectedProject === 0) {
        setSelectedProject(projects.length - 1);
      } else {
        setSelectedProject(prevState => {
          return prevState - 1;
        });
      }
    }
  };

  return (
    <section className="projects">
      <div className={`projects__navigation ${animationClassRight}`}>
        <button className="projects__button" onClick={clickHandler} id="prev">
          <img src={prev} alt="Navigate Next" className="projects__button-icon" />
        </button>
        <button className="projects__button" onClick={clickHandler} id="next">
          <img src={next} alt="Navigate Prev" className="projects__button-icon" />
        </button>
      </div>
      <div className={`projects__phone ${animationClassLeft}`}>
        <iframe title="phone Iframe" className="projects__iframe" src={`https://www.olliehermans.com/${project}`} frameBorder="0"></iframe>
      </div>
      <div className={`projects__pc ${animationClassRight}`}>
        <iframe title="Computer Iframe" className="projects__iframe" src={`https://www.olliehermans.com/${project}`} frameBorder="0"></iframe>
      </div>
      <div className="projects__background"></div>
      <HomeButton targetPageNumber={2} className={`projects__page-button ${animationClassLeft}`} content={"About Me"} />
    </section>
  );
};

export default Projects;
