import "./About.scss";
import maisieAndMe from "./../../assets/maisie-and-me.webp";
import HomeButton from "../home/HomeButton";

const About = () => {
  return (
    <section className="about">
      <div className="about__container">
        <img src={maisieAndMe} alt="Ollie and Maisie (the dog)" className="about__img" />

        <div className="about__text">
          <p>Hi, my name is Ollie. I'm a front-end developer, who is always learning. I am currently focusing one React/Typescript and SCSS.</p>
          <br />
          <p>
            My background as a business consultant and project officer for large organizations and governments around the world sets me apart from other developers — I've spent years on the other side
            of the fence. I also feel like I can safely say my bachelor degree in Forestry Science isn't something most developers have!
          </p>
          <br />
          <p>
            Aside from building things on the web, I enjoy getting out in nature, diving and gardening. You can check out my gardening website{" "}
            <a href="https://www.olliesgarden.com" target="_blank">
              here
            </a>
            .
          </p>
          <br />
          <p>If you are wanting developer who is easy to work with flick me a message.</p>
        </div>
      </div>
      <HomeButton targetPageNumber={3} className={"about__page-button"} content={"Contact"} />
    </section>
  );
};

export default About;
