import classes from "./ContactButton.module.scss";
import send from "./../../assets/arrow_send.svg";

const ContactButton = () => {
  return (
    <button className={classes.button}>
      <div className={classes.button__content}>
        <h1 className={classes.button__text}>Send message</h1>
        <img src={send} alt="Send arrow" className={classes.button__arrow} />
      </div>
    </button>
  );
};

export default ContactButton;
