import "./ContactForm.scss";
import ContactButton from "./ContactButton";
import arrowUp from "./../../assets/arrow_up.svg";

const ContactForm = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="contact">
      <form className="contact__form" action="https://formsubmit.co/81cdcc0159da5217857d1aa484cb4331" method="POST">
        <label htmlFor="name" className="contact__label">
          What's your name?
        </label>
        <input type="text" className="contact__input" name="name" />
        <label htmlFor="email" className="contact__label">
          What's your email
        </label>
        <input type="email" className="contact__input" name="email" />
        <label htmlFor="message" className="contact__label">
          How can I help?
        </label>
        <textarea rows={5} className="contact__input" name="message" />
        <ContactButton />
      </form>
      <footer className="contact__footer">
        <div className="contact__footer-img-container" onClick={scrollToTop}>
          <img className="contact__footer-img" src={arrowUp} alt="Back to top" />
        </div>
        <p className="contact__footer-text">
          Website designed and created by Ollie Hermans | <a href="https://www.olliehermans.com">www.olliehermans.com</a>
        </p>
        <p className="contact__footer-text">All rights reserved by Ollie Hermans. No reuse without permission.</p>
      </footer>
    </section>
  );
};

export default ContactForm;
