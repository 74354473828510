import { useEffect, useRef } from "react";
import "./BackgroundItem.scss";

const BackgroundItem = (props: any) => {
  const nameElement = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (nameElement.current != null) {
      const elementSize = { width: nameElement.current.clientWidth, height: nameElement.current.clientHeight };
      props.getElementSize(elementSize);
    }
  }, []);

  return (
    <>
      <span className="background-item" ref={nameElement}>
        {props.text}
      </span>
    </>
  );
};

export default BackgroundItem;
