import "./App.scss";
import Home from "./components/home/Home";
import Projects from "./components/projects/Projects";
import About from "./components/about/About";
import ContactForm from "./components/contact/ContactForm";

//TODO add animations on scroll for form and projects
//TODO add form functionality
//TODO add some sort of background to the form page
function App() {
  return (
    <>
      <Home />
      <Projects />
      <About />
      <ContactForm />
    </>
  );
}

export default App;
