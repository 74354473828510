import "./HomeButton.scss";
import arrow from "./../../assets/arrow_down.svg";
import scrollDown from "../../helpers/scroll-down";

interface IButtonProps {
  targetPageNumber: number;
  className: string;
  content: string;
}

const HomeButton = (props: IButtonProps) => {
  const clickHandler = () => {
    scrollDown(props.targetPageNumber);
  };

  const className = props.className ? props.className : "";

  return (
    <button className={`home-button ${className}`}>
      <div className="home-button__content" onClick={clickHandler}>
        <h1 className="home-button__text">{props.content}</h1>
        <img src={arrow} alt="Down Arrow" className="home-button__arrow" />
      </div>
    </button>
  );
};

export default HomeButton;
